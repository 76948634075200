import '../../../node_modules/izitoast/dist/css/iziToast.min'
import iziToast from 'izitoast'

const DEFAULT_OPTIONS = {
  position: 'topRight',
  displayMode: 'replace',
  color: 'green',
  timeout: 2000
};

export default class Notification {
  static success(message) {
    iziToast.show({ ...DEFAULT_OPTIONS, message: message });
  }

  static error(message) {
    iziToast.show({ ...DEFAULT_OPTIONS, color: 'red', message: message });
  }
}

